import React from "react";
import RegisterButton from "../components/RegisterButton";
import { useTranslation } from "react-i18next";

const RegistrationPage = () => {
    const { t } = useTranslation();

    const flowSteps = [
        {
            number: 1,
            description: t("step2"),
        },
        {
            number: 2,
            description: t("step3"),
        },
        {
            number: 3,
            description: t("step4"),
        },
    ];

    return (
        <div className="flex flex-col items-center justify-center min-h-screen px-0 md:px-24 md:max-w-4xl md:mx-auto py-24 mt-12">
            <div className="w-full">
                <h1 className="text-4xl font-bold text-center text-white mb-6 uppercase">
                    {t('navbar.registration')}
                </h1>

                <section id="flowchart" className="w-full py-6 px-6 md:px-16">
                    {/* Flowchart Container */}
                    <div className="flex flex-col md:flex-row items-center md:items-start justify-center space-y-8 md:space-y-0 md:space-x-4">
                        {flowSteps.map((step, index) => (
                            <div key={index} className="flex flex-col items-center text-center w-48 md:w-1/4 bg-orange py-8 px-4 rounded-xl h-[18rem]">
                                {/* Step Number */}
                                <div className="w-16 h-24 flex items-center justify-center rounded-full bg-white text-black text-xl font-bold">
                                    {step.number}
                                </div>
                                {/* Step Description */}
                                <p className="mt-4 text-black font-medium leading-relaxed flex items-center h-full">
                                    {step.description} <br />
                                    {step.note && <span className="text-sm text-gray-700">{step.note}</span>}
                                </p>
                            </div>
                        ))}
                    </div>

                    {/* Notes Section */}
                    <div className="mt-12 rounded-lg text-slate-300 text-md leading-relaxed">
                        <h3 className="font-bold text-lg mb-2">Notes:</h3>
                        <p className="mb-1">{t("note1")}</p>
                        <ul className="list-disc space-y-1">
                            <li className="ml-4">{t("note2")}</li>
                            <li className="ml-4">{t("note3")}</li>
                        </ul>
                    </div>
                </section>

                <div className="flex justify-center items-center">
                    <RegisterButton
                        className="bg-orange text-black md:text-xl font-bold py-2 md:px-8 px-6 w-auto h-[2rem] flex items-center justify-center md:h-[3rem] rounded-full hover:bg-black hover:text-orange-600"
                        label={t("homepage.register")}
                    />
                </div>

                {/* Terms and Conditions */}
                <div className="mt-16 text-start text-sm text-slate-300 px-8">
                    <p className="mb-4">{t("tc1")}<b>{t("tc")}</b>{t("tc1_1")}</p>
                    <ul className="list-disc list-inside text-left space-y-2 mb-4">
                        <li>{t("tc2")}</li>
                        <li>{t("tc3")}</li>
                        <li>{t("tc4")}</li>
                        <li>{t("tc5")}</li>
                    </ul>
                    <p className="text-start">{t("tc6")}
                        <span className='text-orange'>
                            {" "}+62852-1198-3843{" "}
                        </span>
                        - Virella
                    </p>
                    <p>
                        {t("contact2")}
                        <a className="text-orange" href="mailto:admin@indonesiaharleyfest.com">admin@indonesiaharleyfest.com</a>

                    </p>
                </div>
            </div>
        </div >
    );
};

export default RegistrationPage;
