import React, { useState } from 'react';
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
    const { t } = useTranslation();

    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');

    const email = 'admin@indonesiaharleyfest.com';

    const handleSubmit = () => {
        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        window.location.href = mailtoLink;
    };

    return (
        <div className="px-8 md:px-24 md:max-w-4xl md:mx-auto py-24 mt-12">
            <h1 className="text-4xl font-bold text-center mb-4 uppercase">{t("navbar.contact")}</h1>

            {/* Subheading */}
            <div className='text-lg text-center mb-4'>
                <p>
                    {t("contact1")} <a href={email}
                        className="text-orange">{email}</a>
                </p>
                <p>
                    {t("contact2")}
                    <span className='text-orange'>
                        +62852-1198-3843{" "}
                    </span>
                    - Virella
                </p>
            </div>


            {/* Contact Info */}
            <div className="space-y-4 mb-8">
                <div className="flex justify-center space-x-6">

                    {/* Social Media */}
                    <div className="flex items-center space-x-4">
                        {/* <a href="https://instagram.com" target="_blank" rel="noreferrer" className="opacity-50 hover:opacity-100 text-3xl">
                            <FaWhatsapp />
                        </a> */}
                        {/* <a href="https://www.instagram.com/indonesiaharleyfest" target="_blank" rel="noreferrer" className="opacity-50 hover:opacity-100  text-3xl">
                            <FaInstagram />
                        </a> */}
                    </div>
                </div>
            </div>

            {/* Message Form */}
            <div className="space-y-4">
                <div>
                    <label htmlFor="subject" className="block text-lg">{t("emailform.subject")}</label>
                    <input
                        type="text"
                        id="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-md text-black focus:outline-none"
                    />
                </div>
                <div>
                    <label htmlFor="body" className="block text-lg">{t("emailform.message")}</label>
                    <textarea
                        id="body"
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-md text-black focus:outline-none"
                        rows="6"
                    />
                </div>
                <button
                    onClick={handleSubmit}
                    className="w-full py-3 bg-orange text-black rounded-3xl transition duration-300 subhead"
                >
                    {t("emailform.sendMessage")}
                </button>
            </div>

            {/* For Further Updates Section */}
            <div className="text-center mt-12 mb-8">
                <p className="text-lg">{t("contact3")}</p>
                <a
                    href="https://www.instagram.com/indonesiaharleyfest"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center space-x-2 px-4 py-0 text-white rounded-full transition duration-300 font-bold">
                    <FaInstagram className='text-2xl' />
                    <span className="text-xl">@indonesiaharleyfest</span>
                </a>
            </div>
        </div>
    );
};

export default ContactUs;
