import React from "react";
import { FaInstagram, FaTwitter, FaFacebook } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const artistsData = [
    {
        name: "Artist 1",
        description: "A talented musician known for their eclectic style.",
        image: "/placeholder.png",
        socials: {
            instagram: "https://instagram.com/artist1",
            twitter: "https://twitter.com/artist1",
            facebook: "https://facebook.com/artist1",
        },
    },
    {
        name: "Artist 2",
        description: "A versatile performer blending music and art.",
        image: "/placeholder.png",
        socials: {
            instagram: "https://instagram.com/artist2",
            twitter: "https://twitter.com/artist2",
            facebook: "https://facebook.com/artist2",
        },
    },
    {
        name: "Artist 3",
        description: "Lorem Ipsum dolor si amet.",
        image: "/placeholder.png",
        socials: {
            instagram: "https://instagram.com/artist2",
            twitter: "https://twitter.com/artist2",
            facebook: "https://facebook.com/artist2",
        },
    },
];

const hide = true;

const ArtistsPage = () => {
    const { t } = useTranslation();

    if (hide) {
        return (
            <div className="mx-auto px-12 md:px-24 py-24 mt-24">
                <h1 className="text-[4rem] font-bold text-center mb-6">
                    {t("coming-soon")}
                </h1>
            </div>
        )
    }

    return (

        <div className="mx-auto px-12 md:px-24 py-24 mt-12">
            <h1 className="text-4xl font-bold text-center mb-6">
                MEET THE ARTISTS
            </h1>
            <div>
                {artistsData.map((artist, index) => (
                    <div
                        key={index}
                        className={`flex flex-col md:flex-row ${index % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"
                            } overflow-hidden mb-8`}
                    >
                        {/* Image Section */}
                        <img
                            src={artist.image}
                            alt={artist.name}
                            className="w-full md:w-1/2 h-48 md:h-auto object-cover"
                        />
                        {/* Description and Socials Section */}
                        <div className={`p-4 md:p-12 md:w-1/2 flex flex-col justify-center ${index % 2 === 0 ? "items-end" : "items-start"
                            }`}>
                            <h2 className="text-xl font-semibold">{artist.name}</h2>
                            <p className="mt-2">{artist.description}</p>
                            <div className="flex items-center gap-4 mt-4">
                                {artist.socials.instagram && (
                                    <a
                                        href={artist.socials.instagram}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="bg-gray-600 text-white p-2 rounded-full hover:bg-gray-700"
                                    >
                                        <FaInstagram />
                                    </a>
                                )}
                                {artist.socials.twitter && (
                                    <a
                                        href={artist.socials.twitter}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="bg-gray-600 text-white p-2 rounded-full hover:bg-gray-700"
                                    >
                                        <FaTwitter />
                                    </a>
                                )}
                                {artist.socials.facebook && (
                                    <a
                                        href={artist.socials.facebook}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="bg-gray-600 text-white p-2 rounded-full hover:bg-gray-700"
                                    >
                                        <FaFacebook />
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ArtistsPage;
