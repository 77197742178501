import React from 'react';
import { useTranslation } from 'react-i18next';

const Sponsors = () => {
    const { t } = useTranslation();

    const sponsors = [
        { name: "Harley-Davidson", logo: "/img/brands/harley_davidson_long.png", style: "w-auto h-[20px] object-contain" },
        { name: "JLM Auto", logo: "/img/brands/jlmauto_white.png", style: "w-auto h-[62px] object-contain" },
    ];

    return (
        <div className="px-12 md:px-24 py-24 mt-12">
            {/* Thank You Header */}
            <div className="text-center mb-12">
                <h1 className="text-3xl font-bold mb-4">{t("sponsors.title")}</h1>
                <div className='text-lg flex flex-col'>
                    <p>{t("sponsors.subtitle")}</p>
                    <p>{t("sponsors.contact1")}</p>
                    <p>{t("sponsors.contact2")}</p>
                </div>
            </div>

            {/* <hr className='mb-8' /> */}

            {/* Sponsors Grid */}
            <div className="flex flex-co sm:flex-row gap-8 items-center justify-center">
                {sponsors.map((sponsor, index) => (
                    <div key={index} className="flex flex-col items-center justify-center">
                        <img
                            src={sponsor.logo}
                            alt={sponsor.name}
                            className={sponsor.style}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Sponsors;
