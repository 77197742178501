import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

const VenuePage = () => {
    const { t } = useTranslation();

    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const sections = [
        {
            id: 1,
            title: t("location.venue"),
            description: t("melasti"),
            carouselImages: ["/img/venue/c_melasti_1.jpg", "/img/venue/c_melasti_2.jpg", "/img/venue/c_melasti_3.jpg"],
            rows: [
                {
                    subtitle: t("location.tourist"),
                    description: t("melasti1"),
                    image: "/img/venue/melasti_1.jpg",
                },
                {
                    subtitle: t("location.natural"),
                    description: t("melasti2"),
                    image: "/img/venue/melasti_2.jpg",
                }
            ],
        },
        {
            id: 2,
            title: t("location.nearby"),
            description: t("shop"),
            carouselImages: ["/img/venue/c_area_1.jpg", "/img/venue/c_area_2.jpg", "/img/venue/c_area_3.jpg"],
            rows: [
                {
                    subtitle: t("location.food"),
                    items: [
                        { title: "White Rock Beach Club", description: "Pantai Melasti, Ungasan, South Kuta, Badung Regency, Bali 80361" },
                        { title: "Oliverra", description: "Umana Bali, Jl. Melasti, Ungasan, Kec. Kuta Sel., Kabupaten Badung, Bali 80364" },
                        { title: "Minoo Beach Club", description: "Pantai Melasti Jl. Pengayutan, Ungasan, South Kuta, Badung Regency, Bali 80361" },
                        // { title: "Whim Bar & Restaurant by Prasana", description: "Jl. Harjani No.1, Ungasan, Kec. Kuta Sel., Kabupaten Badung, Bali 80361" },
                        { title: "Palmilla Beach Club", description: "Ungasan, South Kuta, Badung Regency, Bali" },
                    ],
                    image: "/img/venue/area_1.jpg",
                },
                // {
                //     subtitle: "Shopping Area",
                //     items: [
                //         { title: t("shop1"), description: t("shop1_1") },
                //         { title: t("shop2"), description: t("shop2_1") },
                //     ],
                //     image: "/img/venue/area_2.png",
                // },
                {
                    subtitle: t("location.other"),
                    items: [
                        { title: "Kecak Dance", description: t("other1") },
                        { title: "Katchafest", description: t("other2") },
                        { title: "Melasti Water Carnival", description: t("other3") },
                        { title: "New Years Event", description: t("other4") },
                    ],
                    image: "/img/venue/area_3.jpg",
                },
            ],
        },
    ];

    return (
        <div className="py-12 md:py-24">
            {sections.map((section) => (
                <div key={section.id} className="space-y-8 mb-16">
                    {/* Carousel */}
                    <div className="w-screen h-64 md:h-96 overflow-hidden">
                        <Slider {...carouselSettings}>
                            {section.carouselImages.map((img, index) => (
                                <div key={index}>
                                    <img
                                        src={img}
                                        alt={`carousel-${index}`}
                                        className="w-screen h-64 md:h-96 object-cover"
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>

                    <div className="mx-auto px-4 md:px-12 md:max-w-4xl md:mx-auto">
                        {/* Section Title and Description */}
                        <div className="text-center mb-8">
                            <h2 className="text-3xl font-bold text-white mb-4">{section.title}</h2>
                            <p className="text-xl">{section.description}</p>
                        </div>

                        {/* Rows */}
                        <div className="space-y-8">
                            {section.rows.map((row, index) => (
                                <div
                                    key={index}
                                    className={`flex flex-col-reverse md:flex-row gap-4 ${index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
                                        } md:items-center md:justify-between space-y-4 md:space-y-0`}
                                >
                                    {/* Text Content */}
                                    <div className="md:w-1/2 space-y-2">
                                        <h3 className="text-2xl font-semibold">{row.subtitle}</h3>
                                        {section.id === 2 ? (
                                            <ul className="list-disc pl-5">
                                                {row.items.map((item, idx) => (
                                                    <li key={idx}>
                                                        {item.title}
                                                        {/* : {item.description} */}
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p>{row.description}</p>
                                        )}
                                    </div>

                                    {/* Image */}
                                    <div className="md:w-1/2">
                                        <img
                                            src={row.image}
                                            alt={row.subtitle}
                                            className="w-full h-48 md:h-64 object-cover rounded-lg"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default VenuePage;
