import { useState } from 'react';
import RegisterButton from './RegisterButton';
import { useTranslation } from "react-i18next";

const Newsletter = () => {
    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubscribe = async (e) => {
        e.preventDefault(); // Prevent default form submission

        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            setMessage('Please enter a valid email.');
            return;
        }

        setIsLoading(true); // Start loading spinner
        setMessage(''); // Clear previous messages

        try {
            const response = await fetch(`https://api.indonesiaharleyfest.com/newsletter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage('Successfully subscribed to the newsletter!');
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            } else {
                setMessage(data.error || 'Failed to subscribe. Please try again.');
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        } catch (error) {
            setMessage('An error occurred. Please try again later.');
        } finally {
            setIsLoading(false); // Stop loading spinner
            setTimeout(() => {
                setMessage('');
            }, 5000);
        }
    };

    return (
        <div className="newsletter w-full flex flex-col mx-auto md:mt-8 flex items-start w-100%">
            <div className="flex flex-col md:hidden mb-8 gap-2">
                <RegisterButton className="text-lg text-start tracking-wider text-orange hover:text-white" label={t("homepage.register")} />
                <a href='https://maps.app.goo.gl/FMe5XnRi6R4AEptW6' target="_blank" rel="noreferrer" className="text-lg tracking-wider"><u>{t("footer.see")}</u></a>
            </div>

            <h2 className="text-md md:text-xl font-bold mb-4">{t("footer.newsletter")}</h2>
            <div className="w-auto p-1 rounded-full flex items-center border border-orange-500 w-[100%]">
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t("footer.enter")}
                    className="w-full p-1 rounded-full text-white bg-black focus:outline-none"
                />
                <button
                    onClick={handleSubscribe}
                    className="subscribe-button bg-orange-500 rounded-full hover:bg-orange-600 text-black font-bold text-sm md:text-md py-2 px-1 md:px-8 uppercase"
                >
                    {isLoading ? 'Subscribing...' : t("footer.subscribe")}
                </button>
            </div>
            {message && (
                <p className={`mt-4 text-sm ${message.startsWith('Successfully') ? 'text-green-500' : 'text-red-500'}`}>
                    {message}
                </p>
            )}
        </div>
    );
};

export default Newsletter;
