import { useEffect } from "react";
import RegisterButton from "./RegisterButton";
import { FaWhatsapp, FaInstagram } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import Newsletter from "./Newsletter";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location.pathname]);

    return (
        <footer className="relative bg-black py-8 px-2 text-white font-sc">
            <div className="container mx-auto flex justify-between items-start">
                {/* Logo Section */}
                <div className="w-1/3 flex justify-center items-center">
                    <img src="/logo_final.png" alt="Site Logo" className="md:w-[20rem]" />
                </div>

                {/* Vertical Line */}
                <div className="hidden lg:flex justify-center items-center my-[25%] md:my-auto md:mr-8">
                    {/* <div className="h-8 md:h-12 border-l-2 border-white"></div> */}
                </div>

                <div class="container-footer px-4 w-3/4 md:w-1/2">
                    {/* Site Map Section */}
                    <div className="site-map w-1/3 mr-4">
                        <h2 className="text-md md:text-xl font-bold">{t("footer.sitemap")}</h2>
                        <div className='text-md tracking-wider my-4 text-gray-300 flex flex-col'>
                            {[
                                { path: '/', label: 'IHF2025' },
                                { path: '/registration', label: t('navbar.registration') },
                                { path: '/program', label: t('navbar.program') },
                                { path: '/artists', label: t('navbar.artists') },
                                { path: '/activities', label: t('navbar.activities') },
                                { path: '/venue', label: t('navbar.venue') },
                                { path: '/hotel', label: t('navbar.hotel') },
                                { path: '/sponsors', label: t('navbar.sponsors') },
                                { path: '/gallery', label: t('navbar.gallery') },
                                { path: '/contact', label: t('navbar.contact') },
                            ].map(link => (
                                <Link
                                    key={link.path}
                                    to={link.path}
                                    className={`hover:text-orange-100`}
                                >
                                    {link.label}
                                </Link>
                            ))}
                        </div>
                        <RegisterButton className="hidden md:flex text-lg text-start tracking-wider text-orange hover:text-white uppercase" label={t("footer.register")} />
                    </div>
                    {/* Socials Section */}
                    <div className="socials w-full flex flex-col mx-auto mt-8 flex justify-center items-start w-[125%] md:w-100%">
                        <h2 className="text-md md:text-xl font-bold mb-4">{t("footer.follow")}</h2>
                        <div className="flex space-x-6 text-xl md:text-3xl">
                            {/* <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="opacity-50 hover:opacity-100">
                            <FaFacebookF />
                        </a>
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="opacity-50 hover:opacity-100">
                            <FaXTwitter />
                        </a> */}
                            {/* <a href="https://instagram.com" target="_blank" rel="noreferrer" className="opacity-50 hover:opacity-100">
                                <FaWhatsapp />
                            </a> */}
                            <a href="https://www.instagram.com/indonesiaharleyfest" target="_blank" rel="noreferrer" className="opacity-50 hover:opacity-100">
                                <FaInstagram />
                            </a>
                            {/* <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="opacity-50 hover:opacity-100">
                            <FaYoutube />
                        </a> */}
                        </div>
                    </div>

                    {/* Newsletter Section */}
                    <Newsletter />
                    {/* Location Section */}
                    <div className="location w-1/3">
                        <h2 className="text-md md:text-xl font-bold">{t("footer.location")}</h2>
                        <p className="text-md tracking-wider my-4">
                            {t("locations.1")}<br />
                            {t("locations.2")}<br />
                            {t("locations.3")}
                            {/* Peninsula Island<br />
                        Nusa Dua, Benoa<br />
                        South Kuta, Bandung Regency<br />
                        Bali<br />
                        80363 */}
                        </p>
                        <a href='https://maps.app.goo.gl/FMe5XnRi6R4AEptW6' target="_blank" rel="noreferrer" className="hidden md:flex text-lg tracking-wider uppercase"><u>{t("footer.see")}</u></a>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center mt-12">
                {/* <div className='flex flex-row'>
                <img className='w-[10rem]' src='/img/brands/inchcape.png' />
                <img className='w-[10rem]' src='/img/brands/jlmauto.png' />
            </div> */}
                <div className="text-center px-8 md:px-16">
                    <span className="opacity-50 tracking-wider text-xs md:text-sm">
                        HARLEY-DAVIDSON®, H-D®, HARLEY®, and H.O.G.™ are among the trademarks of Harley-Davidson® Motor COMPANY™, Inc. Other trademarks are the property of their respective owners.<br />© 2024 INDONESIAHARLEYFEST.COM. All rights reserved.
                    </span>
                </div>

            </div>
        </footer>
    )
}

export default Footer;