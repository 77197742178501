import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { LanguageProvider } from "./lib/LanguageContext";
import "./lib/i18n";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import LandingPage from "./pages/landing-page";
import Gallery from "./pages/gallery";
import Sponsors from "./pages/sponsors";
import ContactUs from "./pages/contact";
import HotelRecommendation from "./pages/hotel-recommendation";
import ArtistsPage from "./pages/artists";
import RegistrationPage from "./pages/registration";
import VenuePage from "./pages/venue";
import ActivitiesPage from "./pages/activities";
import Programs from "./pages/programs";

// admin pages
// import LoginPage from "./pages/admin/login";
// import Dashboard from "./pages/admin/dashboard";
// import NewsletterPage from "./pages/admin/newsletter";

const root = ReactDOM.createRoot(document.getElementById("root"));

// This is a component where we will handle the location check
const AppContent = () => {
  const location = useLocation(); // Get current location

  // Check if the current route is an admin route
  const isAdminRoute = location.pathname.startsWith("/admin");

  return (
    <>
      {/* Render Navbar only if not in admin routes */}
      {!isAdminRoute && <Navbar />}

      <Routes>
        {/* Define your Routes here */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/sponsors" element={<Sponsors />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/hotel" element={<HotelRecommendation />} />
        <Route path="/artists" element={<ArtistsPage />} />
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/venue" element={<VenuePage />} />
        <Route path="/activities" element={<ActivitiesPage />} />
        <Route path="/program" element={<Programs />} />

        <Route path="*" element={<Navigate to="/" />} />

        {/* admin pages */}
        {/* <Route path="/admin/login" element={<LoginPage />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/newsletter" element={<NewsletterPage />} /> */}
      </Routes>

      {/* Render Footer only if not in admin routes */}
      {!isAdminRoute && <Footer />}
    </>
  );
};

const App = () => {
  return (
    <LanguageProvider>
      <Router> {/* Wrap everything inside Router */}
        <AppContent />
      </Router>
    </LanguageProvider>
  );
};

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
