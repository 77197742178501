import React from 'react';
import { useTranslation } from 'react-i18next';

const Gallery = () => {
    const { t } = useTranslation();

    // const images = Array.from({ length: 10 }, (_, index) => `/img/gallery/${index + 1}.png`);

    const imgClass = "object-cover w-full h-full rounded-md shadow-lg";
    const images = [
        "/img/gallery/1.jpg",
        "/img/gallery/2.jpg",
        "/img/gallery/3.jpg",
        "/img/gallery/4.jpg",
        "/img/gallery/5.jpg",
        "/img/gallery/6.jpg",
        "/img/gallery/7.jpg",
        "/img/gallery/8.jpg",
        "/img/gallery/9.png",
        "/img/gallery/10.jpg",
        "/img/gallery/11.png",
        "/img/gallery/12.jpg",
        "/img/gallery/13.jpg",
        "/img/gallery/14.jpg",
        "/img/gallery/15.jpg",
        "/img/gallery/16.jpg",
        "/img/gallery/17.jpg",
        "/img/gallery/18.jpg",
        "/img/gallery/19.jpg",
        "/img/gallery/20.jpg",
        "/img/gallery/21.jpg",
        "/img/gallery/22.jpg",
        "/img/gallery/23.jpg",
        "/img/gallery/24.jpg",
        "/img/gallery/25.jpg",
        "/img/gallery/26.jpg",
        "/img/gallery/27.jpg",
        "/img/gallery/28.jpg",
        "/img/gallery/29.jpg",
    ];

    const grids = [
        [0, 1, 2], // Indices for the first grid structure
        [3, 4, 5], // Indices for the second grid structure
        [6, 7, 8], // Indices for the third grid structure
    ];

    return (
        <div className="px-0 md:px-24 py-24 mt-12">
            <h1 className="text-center text-4xl font-bold mb-4">{t("ihf_gallery")}</h1>

            {/* Gallery Sections */}
            <div className="space-y-4 flex flex-col items-center px-4">
                <div>
                    {Array.from({ length: Math.ceil(images.length / 9) }).map((_, gridIndex) => (
                        <div key={gridIndex}>
                            {grids.map((grid, i) => {
                                const start = gridIndex * 9 + grid[0];
                                const gridImages = grid.map((offset) => images[start + offset]).filter(Boolean);

                                return (
                                    <div className="grid grid-cols-3 gap-4 mb-4" key={`${gridIndex}-${i}`}>
                                        {gridImages.map((src, index) => (
                                            <div
                                                className={`relative ${(i === 0 && index === 0) || (i === 2 && index === 1)
                                                    ? "col-span-2 row-span-2"
                                                    : src === "/img/gallery/29.jpg" ? "row-span-2" : "row-span-1"
                                                    }`}
                                                key={src}
                                            >
                                                <img src={src} className={imgClass} />
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Gallery;
