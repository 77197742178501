import React from 'react';
import { useTranslation } from 'react-i18next';

const Programs = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    console.log('currentLanguage:', currentLanguage)

    return (
        <div className="px-4 md:px-24 md:max-w-4xl md:mx-auto py-24 mt-12 flex flex-col items-center text-white relative">
            <h1 className="text-4xl font-bold text-center mb-8">PROGRAM</h1>

            <div className='flex flex-col gap-4'>
                <img
                    src={`/img/rundown/rundown1-${currentLanguage}.png`} />
                <img
                    src={`/img/rundown/rundown2-${currentLanguage}.png`} />
            </div>
        </div>
    );
};

export default Programs;
