import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FaX } from 'react-icons/fa6';
import imageCompression from 'browser-image-compression';

// Pop-Up Component
const Popup = ({ show, onClose, initialBundle = "", customKing }) => {
    const { t } = useTranslation();

    const [currentSection, setCurrentSection] = useState(1);
    const [isPaymentChannelsOpen, setIsPaymentChannelsOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');

    const [selectedPayment, setSelectedPayment] = useState('bank1');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [community, setCommunity] = useState('');
    const [bundle, setBundle] = useState(initialBundle);
    const [paymentProof, setPaymentProof] = useState(null);

    const [country, setCountry] = useState('');
    const [dealership, setDealership] = useState('');
    const [bike, setBike] = useState('');
    const [donor, setDonor] = useState('');
    const [pics, setPics] = useState(null);
    const [video, setVideo] = useState(null);
    const [rideable, setRideable] = useState(true);
    const [mileage, setMileage] = useState('');
    const [genuine, setGenuine] = useState(true);


    useEffect(() => {
        // Lock body scroll when popup is open
        if (show) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        // Clean up the effect when the component unmounts
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [show]);

    if (!show) return null;

    const handleNext = () => {
        setCurrentSection((prevStep) => prevStep + 1);
    };

    const handlePrev = () => {
        setCurrentSection((prevStep) => prevStep - 1);
    };

    const handleFileChange = async (event, field) => {
        const files = Array.from(event.target.files); // Convert FileList to Array

        if (files.length > 0) {
            try {
                if (field === 'pics') {
                    const options = {
                        maxSizeMB: 1, // Maximum size in MB
                        maxWidthOrHeight: 1024, // Maximum width or height in pixels
                        useWebWorker: true,
                    };

                    const processedFiles = await Promise.all(files.map(async (file) => {
                        // Compress each image file
                        const compressedFile = await imageCompression(file, options);
                        const fileName = file.name;
                        const fileExtension = fileName.split('.').pop(); // Get the file extension
                        return new File([compressedFile], `${fileName}.${fileExtension}`, { type: compressedFile.type });
                    }));

                    setPics(processedFiles); // Update this to handle multiple files
                } else if (field === 'video') {
                    // Set the first video file directly without compression
                    setVideo(files[0]); // Assuming only one video
                }
            } catch (error) {
                console.error('Error processing files:', error);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        // Create a plain object to store the form data
        const payload = {
            name,
            phone,
            email,
            community: community || undefined,
            paymentProof: paymentProof ? paymentProof.path : undefined,
        };

        if (customKing) {
            payload.country = country;
            payload.dealership = dealership;
            payload.bike = bike;
            payload.donor = donor;
            payload.pics = pics.map((pic) => pic.name); // Include filenames or additional details as needed
            if (video) {
                payload.video = video.name; // Include filename or other details
            }
            payload.rideable = rideable;
            payload.mileage = mileage;
            payload.genuine = genuine;
        }

        try {
            // api.indonesiaharleyfest.com
            const response = await fetch('https://api.indonesiaharleyfest.com/send-registration', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                setPopupMessage(t('success-form'));
                setShowSuccessPopup(true);
            } else {
                const errorData = await response.text();
                if (errorData === "This email is already registered.") {
                    setPopupMessage(t('failed-form-email'));
                } else {
                    setPopupMessage(t('failed-form'));
                }
                setShowErrorPopup(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setPopupMessage(t('failed-form'));
            setShowErrorPopup(true);
        } finally {
            setIsLoading(false); // Hide spinner
        }
    };


    return (
        <div className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-auto`} onClick={() => {
            onClose();
            setCurrentSection(1);
        }}>
            {isLoading && (
                <div className="loading-overlay">
                    <div class="loader-text"></div>
                    <div className="loader"></div>
                </div>
            )}

            {showSuccessPopup && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white text-black p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
                        <h2 className="text-lg font-semibold">Success</h2>
                        <p className="mt-2">{popupMessage}</p>
                        <button
                            onClick={() => {
                                setShowSuccessPopup(false);
                                onClose();
                            }}
                            className="mt-4 bg-black hover:bg-orange-600 text-orange font-bold py-2 px-4 rounded"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}

            {showErrorPopup && (
                <div className="fixed inset-0 flex justify-center items-center bg-black text-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
                        <h2 className="text-lg font-semibold">Registration Failed</h2>
                        <p className="mt-2">{popupMessage}</p>
                        <button
                            onClick={() => {
                                setShowErrorPopup(false);
                                onClose();
                            }}
                            className="mt-4 bg-black hover:bg-orange-600 text-orange font-bold py-2 px-4 rounded"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}

            <div className="bg-white text-black px-2 md:px-10 py-8 rounded-lg shadow-lg max-w-sm relative flex flex-col justify-center items-center overflow-auto my-auto" onClick={(event) => event.stopPropagation()} style={{ minWidth: '50vw', overflowY: 'auto' }} >
                <button
                    onClick={() => {
                        onClose();
                        setCurrentSection(1);
                    }}
                    className="absolute top-4 right-4 text-black hover:text-gray-600"
                >
                    <FaX size={16} />
                </button>
                {currentSection === 1 ? (
                    <div className="flex items-center justify-center">
                        <form onSubmit={handleSubmit} className="bg-white p-2">
                            {customKing ?
                                <div className="mb-4">
                                    <label htmlFor="name" className="block text-sm font-medium mb-1">{t("name-ck-label")}<span className="text-red-500 ml-1">*</span></label>
                                    <input
                                        id="name"
                                        type="text"
                                        placeholder={t("name-ck-placeholder")}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className="w-full p-2 rounded-md border border-gray-300"
                                        required
                                    />
                                </div> :
                                <div className="mb-4">
                                    <label htmlFor="name" className="block text-sm font-medium mb-1">{t("name-label")}<span className="text-red-500 ml-1">*</span></label>
                                    <input
                                        id="name"
                                        type="text"
                                        placeholder={t("name-placeholder")}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className=" w-full p-2 rounded-md border border-gray-300"
                                        required
                                    />
                                </div>
                            }
                            <div className="mb-4">
                                <label htmlFor="phone" className="block text-sm font-medium mb-1">{t("phone-label")}<span className="text-red-500 ml-1">*</span></label>
                                <input
                                    id="phone"
                                    type="number"
                                    placeholder={t("phone-placeholder")}
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    className="w-full p-2 rounded-md border border-gray-300"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-sm font-medium mb-1">{t("email-label")}<span className="text-red-500 ml-1">*</span></label>
                                <input
                                    id="email"
                                    type="text"
                                    placeholder={t("email-placeholder")}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full p-2 rounded-md border border-gray-300"
                                    required
                                />
                            </div>
                            {customKing ? (
                                <>
                                    <div className="mb-4">
                                        <label htmlFor="country" className="block text-sm font-medium mb-1">{t("country-label")}<span className="text-red-500 ml-1">*</span></label>
                                        <input
                                            id="country"
                                            type="text"
                                            placeholder={t("country-placeholder")}
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                            className="w-full p-2 rounded-md border border-gray-300"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="dealership" className="block text-sm font-medium mb-1">{t("dealership-label")}<span className="text-red-500 ml-1">*</span></label>
                                        <input
                                            id="dealership"
                                            type="text"
                                            placeholder={t("dealership-placeholder")}
                                            value={dealership}
                                            onChange={(e) => setDealership(e.target.value)}
                                            className="w-full p-2 rounded-md border border-gray-300"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="bike" className="block text-sm font-medium mb-1">{t("bike-label")}<span className="text-red-500 ml-1">*</span></label>
                                        <input
                                            id="bike"
                                            type="text"
                                            placeholder={t("bike-placeholder")}
                                            value={bike}
                                            onChange={(e) => setBike(e.target.value)}
                                            className="w-full p-2 rounded-md border border-gray-300"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="donor" className="block text-sm font-medium mb-1">{t("donor-label")}<span className="text-red-500 ml-1">*</span></label>
                                        <input
                                            id="donor"
                                            type="text"
                                            placeholder={t("donor-placeholder")}
                                            value={donor}
                                            onChange={(e) => setDonor(e.target.value)}
                                            className="w-full p-2 rounded-md border border-gray-300"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="pics" className="block text-sm font-medium mb-1">{t("pics-label")}<span className="text-red-500 ml-1">*</span></label>
                                        <input
                                            type="file"
                                            accept=".png,.jpg,.jpeg"
                                            onChange={(e) => handleFileChange(e, 'pics')}
                                            className="w-full p-2 rounded-lg border border-gray-300"
                                            multiple
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="video" className="block text-sm font-medium mb-1">{t("video-label")}</label>
                                        <input
                                            type="file"
                                            accept=".mp4,.mov,.avi,.mkv"
                                            onChange={(e) => handleFileChange(e, 'video')}
                                            className="w-full p-2 rounded-lg border border-gray-300"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="rideable" className="block text-sm font-medium mb-1">
                                            {t("rideable-label")}
                                            <span className="text-red-500 ml-1">*</span>
                                        </label>
                                        <div className="flex items-center space-x-4">
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    name="rideable"
                                                    value={"Yes"}
                                                    checked={rideable === 'Yes'}
                                                    onChange={(e) => setRideable(e.target.value)}
                                                    className="form-radio h-4 w-4 text-orange-600"
                                                    required
                                                />
                                                <span className="ml-2">Yes</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    name="rideable"
                                                    value="No"
                                                    checked={rideable === 'No'}
                                                    onChange={(e) => setRideable(e.target.value)}
                                                    className="form-radio h-4 w-4 text-orange-600"
                                                    required
                                                />
                                                <span className="ml-2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="mileage" className="block text-sm font-medium mb-1">{t("mileage-label")}<span className="text-red-500 ml-1">*</span></label>
                                        <input
                                            id="mileage"
                                            type="text"
                                            placeholder={t("mileage-placeholder")}
                                            value={mileage}
                                            onChange={(e) => setMileage(e.target.value)}
                                            className="w-full p-2 rounded-md border border-gray-300"
                                            required
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="genuine" className="block text-sm font-medium mb-1">
                                            {t("genuine-label")}
                                            <span className="text-red-500 ml-1">*</span>
                                        </label>
                                        <div className="flex items-center space-x-4">
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    name="genuine"
                                                    value={"Yes"}
                                                    checked={genuine === 'Yes'}
                                                    onChange={(e) => setGenuine(e.target.value)}
                                                    className="form-radio h-4 w-4 text-orange-600"
                                                    required
                                                />
                                                <span className="ml-2">Yes</span>
                                            </label>
                                            <label className="inline-flex items-center">
                                                <input
                                                    type="radio"
                                                    name="genuine"
                                                    value="No"
                                                    checked={genuine === 'No'}
                                                    onChange={(e) => setGenuine(e.target.value)}
                                                    className="form-radio h-4 w-4 text-orange-600"
                                                    required
                                                />
                                                <span className="ml-2">No</span>
                                            </label>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="mb-4">
                                        <label htmlFor="community" className="block text-sm font-medium mb-1">{t("community-label")}<span className="text-red-500 ml-1">*</span></label>
                                        <select
                                            id="community"
                                            value={community}
                                            onChange={(e) => setCommunity(e.target.value)}
                                            className="w-full bg-white p-2 rounded-md border border-gray-300 hover:cursor-pointer"
                                            required
                                        >
                                            <option value="" disabled>{t("community-placeholder")}</option>
                                            <option value="Anak Elang">H.O.G.™ Anak Elang Jakarta Chapter</option>
                                            <option value="Indomobil Jakarta">H.O.G.™ Indomobil Jakarta Chapter</option>
                                            <option value="Kalimas Solo">H.O.G.™ Kalimas Solo Chapter</option>
                                            <option value="Sarana Bali">H.O.G.™ Sarana Bali Chapter</option>
                                            <option value="Siliwangi Bandung">H.O.G.™ Siliwangi Bandung Chapter</option>
                                            <option value="Community">Community</option>
                                            <option value="Lainnya">Lainnya/Others</option>
                                        </select>
                                    </div>

                                </>
                            )}
                            <div className="mb-4">
                                <span className="text-red-500 ml-1">*{t("required")}</span>
                            </div>
                            {/* <div className="mb-4">
                                <label htmlFor="email" className="block text-sm font-medium mb-1">{t("email-label")}</label>
                                <input
                                    id="email"
                                    type="email"
                                    placeholder={t("email-placeholder")}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full p-2 rounded-md border border-gray-300"
                                // required
                                />
                            </div> */}
                            {/* bundles */}
                            {/* <div className="mb-4">
                                <label htmlFor="bundle" className="block text-sm font-medium mb-1">{t("bundle-label")}</label>
                                <select
                                    id="bundle"
                                    value={bundle}
                                    onChange={(e) => setBundle(e.target.value)}
                                    className="w-full bg-white p-2 rounded-md border border-gray-300 hover:cursor-pointer"
                                    required
                                >
                                    <option value="" disabled>{t("bundle-placeholder")}</option>
                                    <option value="Basic">Basic Bundle</option>
                                    <option value="Best Deal">Best Deal Bundle</option>
                                    <option value="Limited">Limited Bundle</option>
                                </select>
                            </div> */}


                            {/* H.O.G.™ Kalimas
                                H.O.G.™ Siliwangi
                                H.O.G.™ Anak Elang
                                H.O.G.™ Indomobil
                                H.O.G.™ Sarana */}
                            <div className="flex justify-between mt-4">
                                <div></div>
                                <button
                                    type="submit"
                                    className="bg-black hover:bg-orange-600 hover:text-black text-orange font-bold py-2 px-4 rounded"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit} className='overflow-y-auto py-8 px-2'>
                        <p>{t('amount-label')}</p>
                        <p className="mb-6 text-2xl font-bold">IDR {bundle === 'basic' ? '1.000' : bundle === 'best-deal' ? '1.500' : '2.000'}.000,-</p>

                        <div className="mb-6 border border-gray-300 rounded-lg p-2">
                            <div
                                className="flex items-center justify-between cursor-pointer"
                                onClick={() => setIsPaymentChannelsOpen(!isPaymentChannelsOpen)}
                            >
                                <span className="text-lg font-bold">{t('payment-channel')}</span>
                                {isPaymentChannelsOpen ? (
                                    <FaChevronUp size={18} />
                                ) : (
                                    <FaChevronDown size={18} />
                                )}
                            </div>
                            <div
                                className={`overflow-hidden max-w-80 ${isPaymentChannelsOpen ? 'min-h-72 h-72' : 'h-0'
                                    }`}
                                style={{ transition: "height 0.25s ease-in-out" }}
                            >
                                {isPaymentChannelsOpen && (
                                    <div className="my-4">
                                        <div className="flex flex-wrap gap-2 md:gap-4">
                                            {['bank1', 'bank2', 'bank3'].map((bank) => (
                                                <button
                                                    id={bank}
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault(); // Prevent default behavior if inside a form
                                                        setSelectedPayment(bank);
                                                    }}
                                                    className={`w-18 h-12 flex items-center justify-center rounded-lg ${selectedPayment === bank ? 'opacity-100' : 'opacity-50'} focus:outline-none`}
                                                >
                                                    <img
                                                        src={`/placeholder.png`}
                                                        alt={`${bank} logo`}
                                                        className="w-20 h-10"
                                                    />
                                                </button>
                                            ))}
                                            {selectedPayment && (
                                                <div className="mt-4">
                                                    <h3 className="text-md mb-2">
                                                        {t('payment-instructions.title', { bank: selectedPayment })}
                                                    </h3>
                                                    <ul className="list-disc list-inside pl-4 md:text-md text-sm">
                                                        {t(`payment-instructions.${selectedPayment}`, { returnObjects: true })?.map((instruction, index) => (
                                                            <li key={index} className="mb-2">{instruction}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="mb-6">
                            <p className="text-sm font-bold">{t('account-number')}</p>
                            <p className="text-sm font-mono bg-gray-100 p-2 rounded">{selectedPayment === 'bank1' ? '1234 - 5678 - 9012' : selectedPayment === 'bank2' ? '3456 - 7890 - 8987' : '5555 - 6666 - 7777'}</p>
                        </div>

                        <div className="mb-6">
                            <p className="text-sm font-bold mb-2">{t('payment-label')}</p>
                            <input
                                type="file"
                                accept=".png,.jpg,.jpeg"
                                onChange={handleFileChange}
                                className="w-full p-2 rounded-lg border border-gray-300"
                            // required
                            />
                        </div>

                        <div className="flex justify-between mt-4">
                            <button
                                onClick={handlePrev}
                                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Previous
                            </button>
                            <button
                                type="submit"
                                className="bg-black hover:bg-orange-600 hover:text-black text-orange font-bold py-2 px-4 rounded"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                )}

            </div>
        </div>
    );
};

const RegisterButton = ({ img, className, label, initialBundle, customKing = false }) => {
    const [isPopupVisible, setPopupVisible] = useState(false);

    const handleRegisterClick = () => {
        setPopupVisible(true);
    };

    const handleClosePopup = () => {
        setPopupVisible(false);
    };

    if (img) {
        return (
            <>
                <div className='hover:cursor-pointer'>
                    <img src={img} onClick={handleRegisterClick} />
                </div>
                <Popup show={isPopupVisible} onClose={handleClosePopup} initialBundle={initialBundle} customKing={customKing} />
            </>)
    }

    return (
        <div>
            <button
                onClick={handleRegisterClick}
                className={className}
            >
                {label}
            </button>
            <Popup show={isPopupVisible} onClose={handleClosePopup} initialBundle={initialBundle} customKing={customKing} />
        </div>
    );
};

export default RegisterButton;
