import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    navbar: {
                        "registration": "Registration",
                        "program": "Program",
                        "artists": "Artists & Performers",
                        "activities": "Activities & Attractions",
                        "venue": "Festival Venue",
                        "hotel": "Hotel Recommendation",
                        "sponsors": "Sponsors",
                        "gallery": "IHF Gallery",
                        "contact": "Contact Us",
                    },

                    locations: {
                        "1": "Melasti Beach",
                        "2": "South Kuta, Bandung Regency",
                        "3": "Bali, Indonesia",
                    },

                    homepage: {
                        "event": "The 1st Harley-Davidson® official event in Indonesia.",
                        "register": "Register Here",
                        "gates": "Festival Gates Open",
                        "direction": "Get Directions",
                        "join": "JOIN US",
                    },

                    emailform: {
                        "subject": "Subject",
                        "message": "Message",
                        "sendMessage": "SEND MESSAGE",
                    },

                    "coming-soon": "Coming Soon!",

                    attraction: {
                        "entertainment": "Entertainment",
                        "exhibition": "Exhibition & Custom Zone",
                    },

                    location: {
                        "venue": "Melasti Beach Venue",
                        "tourist": "Tourist Activities",
                        "natural": "Natural Beauty of Melasti Beach",
                        "nearby": "Nearby Area",
                        "food": "Food and Beverages",
                        "other": "Other Events & Festivals",
                    },

                    footer: {
                        "sitemap": "Site Map",
                        "location": "Location",
                        "see": "See On Map",
                        "register": "REGISTER HERE",
                        "follow": "Follow Us",
                        "newsletter": "Newsletter",
                        "enter": "Enter Your Email to Subscribe",
                        "subscribe": "Subscribe",
                    },

                    time: {
                        "clock": "01:00 PM GMT+8",
                        "date": "15 February 2025",
                    },

                    hotel: {
                        "recommendation": "Hotel Recommendations",
                        "distance": "minutes away",
                        "promo": "Promo Code",
                        "booking": "Booking Number",

                        "roomtype": "Room Type",
                        "price": "Price",
                        "corpo": "Corporate Rate",
                        "available": "Availability",
                        "roomsAvailable": "rooms available",

                        "renaissance": "Nestled close to picturesque beaches, the Renaissance Bali Uluwatu Resort & Spa is a sanctuary of tranquility and luxury, as many guests have noted.",
                        "pandawa": "Pandawa Hill Resort is set in Nusa Dua, attractively located in the Kutuh district. It features an outdoor swimming pool, a bar, and onsite dining, a well-appointed spa, and spacious rooms.",
                        "swiss": "Swiss-Belresort Pecatu is an international standard four-star resort in a hilly resort complex known as Pecatu Indah Resort.",
                        "grande": "Nestled within one of Bali's Golf Courses, under blue skies and surrounded by a flourishing tropical landscape, lies the idyllic island retreat of Le Grande Bali Hotel.",
                        "klapa": "Facing the magnificent Indian Ocean, Klapa Resort commands a convenient direct access to Dreamland Beach and New Kuta Golf Course.",
                        "tulip": "The 5-star Royal Tulip Springhill Resort Jimbaran, a perfect place for your holiday or business stay.",
                        "radisson": "Perched above the shore, the Radisson Blu Resort, Bali Uluwatu overlooks the Indian Ocean and local farmland, offering tranquil views from each of our spacious hotel rooms and suites.",
                        "fox": "As the first FOX Hotel in Bali, we provide a unique approach to Jimbaran hotels by providing a signature touch of hospitality on this paradise island.",
                        "anantara": "Anantara Uluwatu Bali Resort unfolds on dramatic cliffside above the Indian ocean. Terraced architecture ensures ocean views from every angle. Dine, swim, and enjoy the jacuzzi against a panorama of sunset at our Bali resort's luxurious suites.",
                        "meridien": "Explore Bali in style at Le Meridien Bali Jimbaran. This family-friendly Jimbaran resort is situated near the beach.",
                        "jimbaran": "Jimbaran Bay Beach Resort & Spa, an independent, family-friendly retreat.",
                        "relaxing": "Bali Relaxing Resort & Spa is set at the most popular point for sea sport activities, nestled amidst a lush tropical garden and a white sandy beach. Ideally located in Tanjung Benoa Bay, this hotel offers spectacular beach views.",
                    },

                    sponsors: {
                        "title": "Want To Be Part of The Harley-Davidson® Festival in Indonesia?",
                        "subtitle": "For Sponsor Please Contact:",
                        "contact1": "+62856-1647-949 - Sonny",
                        "contact2": "+62852-1198-3843 - Virella",
                    },

                    "melasti-beach": "Melasti Beach",

                    "home": "Home",
                    "events": "Events",
                    "schedule": "Schedule",
                    "faq": "FAQ",
                    "coming_soon": "Coming Soon!",
                    "hero": "The 1st Harley-Davidson® official event in",

                    "desc1": "This outdoor motorcycle event will unite all the Harley-Davidson® Enthusiasts in Indonesia, extending a warm welcome to the all Harley-Davidson® community and riders. Spanning from morning to night, this one-day extravaganza will be packed with a variety of entertainment and activities designed to engage both the community and the general public. From exhilarating live performances and thrilling stunt shows to exciting games and interactive displays, there will be something for everyone to enjoy. The event aims to foster a strong sense of unity among Harley-Davidson® enthusiasts, celebrate the rich heritage of the brand, and provide a platform for riders to connect and share their passion.",
                    "desc2": "The Indonesia Harley® Fest seeks to bring together Harley-Davidson® enthusiasts from all walks of life, celebrating the brand's rich history and the spirit of adventure and freedom it embodies. The event aims to foster a strong sense of unity among riders, providing a platform for them to connect,share their passion, and create lasting memories.",
                    "fest_location": "Festival Location",
                    "fest_follow": "Follow the Event",

                    "step1": "Invitations are distributed by Harley-Davidson®",
                    "step2": "Participants fill in their registration data",
                    "step3": "Participants get their pass ID via email",
                    "step4": "Entry allowed only for those with an official ID pass",

                    "note1": "Participants filtering is divided into 2 points based on the official wrist band:",
                    "note2": "Motor Parking Area",
                    "note3": "Entrance Gate",

                    "tc": "Terms and Conditions",
                    "tc1": "By registering, you agree to the following ",
                    "tc1_1": ":",
                    "tc2": "Registration is required for participation in the event.",
                    "tc3": "Participants must comply with the event's safety rules.",
                    "tc4": "Unauthorized recording or photography is prohibited.",
                    "tc5": "We are not responsible for any personal injuries or loss of property.",
                    "tc6": "For any further queries, feel free to contact:",

                    "melasti": "Explore the scenic beauty of Melasti Beach, famous for its white sandy beach and exciting beach clubs,",
                    "melasti1": "Sunbathe on the beach, play in the sand, and savor culinary delights at Melasti Beach. Explore the area, watch the Kecak dance, and take memorable selfies.",
                    "melasti2": "Melasti Beach is a captivating display of Bali's natural beauty. Framed by dramatic limestone cliffs, the beach boasts pristine white sand that stretches invitingly towards the crystal-clear turquoise waters of the Indian Ocean. The surroundings offer breathtaking views and a sense of seclusion. It provides a tranquil escape, where the gentle lapping of waves creates a truly serene atmosphere.",

                    "shop": "A luxurious experience combining leisure, music, and stunning views of the ocean.",
                    "shop1": "Kuta Art Market",
                    "shop1_1": "Located at Melasti Street No.86, Kuta, Badung Regency, Bali. This market sells various Bali souvenirs, such as paintings, keychains, clothing, and other local products, and offers affordable prices.",
                    "shop2": "Merta Nadi Art Market",
                    "shop2_1": "Located at Melasti Street, Bali. This market sells various items, such as sunglasses, dresses, t-shirts, hats, and sandals.",

                    "other1": "Held at 5PM",
                    "other2": "A reggae music festival",
                    "other3": "With appearance of Kecak dance, Water Carnival dance, and Baleganjur",
                    "other4": "Enjoy a new years event accompanied with the coastal view",

                    "morning": "MORNING",
                    "afternoon": "AFTERNOON",
                    "evening": "EVENING",

                    "ihf_gallery": "GALLERY",

                    "contact1": "You can contact us via email at ",
                    "contact2": " or reach out via the number ",
                    "contact3": "For further updates, follow us at:",

                    "ck1": "Join the ",
                    "ck2": "competition and become",
                    "ck3": "the King of the Kings.",

                    "required": "Please fill the required forms",

                    "name-label": "Name",
                    "name-placeholder": "What should we call you?",

                    "name-ck-label": "Participant Name (customer / dealer / custom builder)",
                    "name-ck-placeholder": "Please enter the name",

                    "country-label": "Country",
                    "country-placeholder": "Please enter your country",

                    "phone-label": "Phone Number",
                    "phone-placeholder": "Please enter your phone number",

                    "phone-label": "Phone Number",
                    "phone-placeholder": "Please enter your phone number",

                    "email-label": "Email Address",
                    "email-placeholder": "Please enter your email address",

                    "bundle-label": "Select Bundle",
                    "bundle-placeholder": "Please select a bundle",

                    "community-label": "Select Community",
                    "community-placeholder": "Please select your community",

                    "dealership-label": "Associated Dealership",
                    "dealership-placeholder": "Please enter your associated dealership",

                    "bike-label": "Bike Nickname",
                    "bike-placeholder": "What should we call your bike?",

                    "donor-label": "Donor Model",
                    "donor-placeholder": "Please enter your donor model",

                    "pics-label": "Pictures from all 4 angles (specs)",
                    "pics-placeholder": "Please upload 4 pictures of your bike from all 4 angles",

                    "video-label": "Video (optional)",
                    "video-placeholder": "You can upload a video of your bike here (optional)",

                    "rideable-label": "Is your bike rideable?",

                    "mileage-label": "Current Mileage",
                    "mileage-placeholder": "Please enter your current mileage",

                    "genuine-label": "Does your bike use at least 50% of genuine Harley Davidson parts?",

                    "amount-label": "Amount to pay",
                    "payment-channel": "Payment Channel",
                    "account-number": "Account Number",
                    "payment-label": "Please upload your proof of payment here",

                    "success-form": "Your registration has been sent! Please check you email for the QR Code.",

                    "failed-form": "Sorry, there was a problem when sending your registration data. Please try again later.",
                    "failed-form-email": "Your email is already registered.",

                    "payment-instructions": {
                        "title": "How to pay",
                        "bank1": [
                            "Transfer the amount to the account number provided.",
                            "Use the reference code mentioned in the confirmation email.",
                            "Ensure to complete the payment before the deadline."
                        ],
                        "bank2": [
                            "Deposit the amount at any branch of Bank 2.",
                            "Write down your order number on the deposit slip.",
                            "Submit the deposit slip at the counter."
                        ],
                        "bank3": [
                            "Pay via Bank 3’s online banking platform.",
                            "Follow the steps outlined in the online banking portal.",
                            "Attach a screenshot of the transaction in your confirmation email."
                        ]
                    },

                    "faq1": "You can always email us at ",
                    "faq2": "for further information",

                    "faqs": [
                        {
                            question: "How do I register?",
                            answer: "You can register for the event by visiting our registration page on the website. Fill out the required information and follow the instructions to complete your registration."
                        },
                        {
                            question: "Where can I purchase tickets?",
                            answer: "Tickets can be purchased on our website or at the event entrance, subject to availability."
                        },
                        {
                            question: "What can I bring?",
                            answer: "You can bring personal items such as water bottles, snacks, and cameras. Please check the event guidelines on our website for a detailed list of permitted and prohibited items."
                        }
                    ],

                    "program1": "The gate opens for attendees to start entering the venue and exploring the grounds",
                    "program2": "Enjoy the F&B and Sponsor Area, and performance by local band",
                    "program3": "Arrival of Rolling Thunder and games with MC",
                    "program4": "Start of H.O.G.™ Touring Video Competition and Opening Ceremony",
                    "program5": "Sunset DJ Performance",
                    "program6": "Break and the end of H.O.G.™ Touring Video Competition",
                    "program7": "Sponsored content and performance by local band",
                    "program8": "Exciting games hosted by the MC with opportunities for attendees to participate and win prizes",
                    "program9": "Enjoy the F&B and Sponsor Area, DJ Performance",
                    "program10": "Awarding Ceremony",
                    "program11": "Performance by a soon-to-be-announced guest star",
                }
            },
            id: {
                translation: {
                    navbar: {
                        "registration": "Registrasi",
                        "program": "Program",
                        "artists": "Artis & Pertunjukan",
                        "activities": "Kegiatan & Atraksi",
                        "venue": "Lokasi Festival",
                        "hotel": "Rekomendasi Hotel",
                        "sponsors": "Sponsor",
                        "gallery": "Galeri IHF",
                        "contact": "Hubungi Kami",
                    },

                    locations: {
                        "1": "Pantai Melasti",
                        "2": "Kuta Selatan, Kabupaten Bandung",
                        "3": "Bali, Indonesia",
                    },

                    homepage: {
                        "event": "Acara resmi Harley-Davidson® pertama di Indonesia.",
                        "register": "DAFTAR DI SINI",
                        "gates": "Gerbang Dibuka",
                        "direction": "Arah Ke Lokasi",
                        "join": "BERGABUNGLAH DENGAN KAMI",
                    },

                    emailform: {
                        "subject": "Judul",
                        "message": "Pesan",
                        "sendMessage": "KIRIM PESAN",
                    },

                    "coming-soon": "Segera Hadir!",

                    attraction: {
                        "entertainment": "Hiburan",
                        "exhibition": "Pameran & Zona Motor Custom",
                    },

                    location: {
                        "venue": "Lokasi Acara - Pantai Melasti",
                        "tourist": "Aktivitas Wisata",
                        "natural": "Keindahan Alam Pantai Melasti",
                        "nearby": "Area Sekitar",
                        "food": "Info Kuliner",
                        "other": "Acara & Festival Lainnya",
                    },

                    footer: {
                        "sitemap": "Peta Situs",
                        "location": "Lokasi",
                        "see": "Lihat Di Peta",
                        "register": "Daftar Di Sini",
                        "follow": "Ikuti Kami",
                        "newsletter": "Buletin",
                        "enter": "Masukkan Email Anda untuk Berlangganan",
                        "subscribe": "Berlangganan",
                    },

                    time: {
                        "clock": "13:00 WITA",
                        "date": "15 Februari 2025",
                    },

                    hotel: {
                        "recommendation": "Rekomendasi Hotel",
                        "distance": "menit (waktu tempuh)",
                        "promo": "Kode Promo",
                        "booking": "Nomor Pemesanan",

                        "roomtype": "Tipe Kamar",
                        "price": "Harga",
                        "corpo": "Harga Korporasi",
                        "available": "Ketersediaan",
                        "roomsAvailable": "kamar tersedia",

                        "renaissance": "Terletak dekat dari pantai-pantai yang indah, Renaissance Bali Uluwatu Resort & Spa adalah sebuah surga dengan ketenangan dan kemewahan, seperti yang telah diungkapkan oleh banyak tamu.",
                        "pandawa": "Pandawa Hill Resort terletak di Nusa Dua, dengan lokasi yang menarik di distrik Kutuh. Menawarkan kolam renang outdoor, bar, dan restoran di tempat, spa yang lengkap, serta kamar-kamar yang luas.",
                        "swiss": "Swiss-Belresort Pecatu adalah resor bintang empat berstandar internasional yang terletak di kompleks resor berbukit yang dikenal sebagai Pecatu Indah Resort.",
                        "grande": "Terletak di salah satu Lapangan Golf Bali, di bawah langit biru dan dikelilingi oleh pemandangan tropis yang subur, terdapat tempat peristirahatan pulau yang indah dari Le Grande Bali Hotel.",
                        "klapa": "Menghadap Samudra Hindia yang megah, Klapa Resort menawarkan akses langsung yang mudah ke Pantai Dreamland dan Lapangan Golf New Kuta.",
                        "tulip": "Royal Tulip Springhill Resort Jimbaran bintang 5, tempat yang sempurna untuk liburan atau perjalanan bisnis Anda.",
                        "radisson": "Bertengger di atas pantai, Radisson Blu Resort, Bali Uluwatu menghadap Samudra Hindia dan lahan pertanian lokal, menawarkan pemandangan yang tenang dari setiap kamar dan suite hotel kami yang luas.",
                        "fox": "Sebagai hotel FOX pertama di Bali, kami memberikan pendekatan unik sebagai hotel di Jimbaran dengan memberikan sentuhan keramahan khas di pulau dewata ini.",
                        "anantara": "Anantara Uluwatu Bali Resort terbentang di tebing dramatis di atas Samudra Hindia. Arsitektur bertingkat memastikan pemandangan laut dari setiap sudut. Bersantap, berenang, dan 1  menikmati jacuzzi dengan panorama matahari terbenam di suite mewah resor Bali kami.",
                        "meridien": "Jelajahi Bali dengan gaya di Le Meridien Bali Jimbaran. Resor Jimbaran yang ramah keluarga ini terletak di dekat pantai.",
                        "jimbaran": "Jimbaran Bay Beach Resort & Spa, sebuah tempat peristirahatan independen dan ramah keluarga.",
                        "relaxing": "Bali Relaxing Resort & Spa terletak di titik paling populer untuk aktivitas olahraga laut, memiliki taman tropis yang rimbun dan terletak di pinggir pantai berpasir putih. Memiliki lokasi ideal di Teluk Tanjung Benoa, hotel ini menawarkan pemandangan pantai yang spektakuler.",
                    },

                    "home": "Home",
                    "events": "Events",
                    "schedule": "Schedule",
                    "faq": "FAQ",
                    "coming_soon": "Segera Hadir!",
                    "hero": "Event official Harley-Davidson® pertama di",

                    "desc1": "Acara motor outdoor ini akan menyatukan semua Harley-Davidson® Enthusiast di Indonesia, memberikan sambutan hangat kepada seluruh komunitas Harley-Davidson® dan para pengendara. Berlangsung dari pagi hingga malam, perayaan sehari ini akan dipenuhi dengan berbagai hiburan dan kegiatan yang dirancang untuk melibatkan komunitas dan masyarakat umum. Mulai dari pertunjukan langsung yang menggetarkan, pertunjukan aksi yang mendebarkan, hingga permainan seru dan tampilan interaktif, akan ada sesuatu untuk dinikmati oleh semua orang. Acara ini bertujuan untuk mempererat rasa persatuan di antara para penggemar Harley-Davidson®, merayakan warisan kaya merek ini, dan menyediakan platform bagi para pengendara untuk terhubung dan berbagi semangat mereka.",

                    "desc2": "Indonesia Harley® Fest bertujuan untuk menyatukan para penggemar Harley-Davidson® dari berbagai lapisan kehidupan, merayakan sejarah kaya merek ini dan semangat petualangan serta kebebasan yang diwakilinya. Acara ini bertujuan untuk mempererat rasa persatuan di antara para pengendara, menyediakan platform bagi mereka untuk terhubung, berbagi semangat mereka, dan menciptakan kenangan yang tak terlupakan.",
                    "fest_location": "Lokasi Festival",
                    "fest_follow": "Ikuti Acara Ini",

                    "step1": "Undangan didistribusikan oleh Harley-Davidson®",
                    "step2": "Peserta mengisi data pendaftaran",
                    "step3": "Peserta mendapatkan ID pass melalui email",
                    "step4": "Peserta dengan ID pass resmi diperbolehkan masuk",

                    "note1": "Penyaringan peserta dibagi menjadi 2 poin berdasarkan gelang resmi:",
                    "note2": "Area Parkir Motor",
                    "note3": "Gerbang Masuk",

                    "tc": "Syarat dan Ketentuan",
                    "tc1": "Dengan mendaftar, Anda menyetujui ",
                    "tc1_1": " berikut:",
                    "tc2": "Pendaftaran diperlukan untuk berpartisipasi dalam acara ini.",
                    "tc3": "Peserta harus mematuhi peraturan keselamatan acara.",
                    "tc4": "Perekaman atau fotografi tanpa izin dilarang.",
                    "tc5": "Kami tidak bertanggung jawab atas cedera pribadi atau kehilangan barang.",
                    "tc6": "Untuk pertanyaan lebih lanjut, silakan hubungi:",

                    "melasti": "Jelajahi keindahan Pantai Melasti, terkenal dengan pasir putihnya yang lembut dan klub pantai yang menarik.",
                    "melasti1": "Berjemur di pantai, bermain pasir, dan menikmati kelezatan kuliner di Pantai Melasti. Jelajahi daerah sekitar, saksikan tarian Kecak, dan ambil foto selfie yang tak terlupakan.",
                    "melasti2": "Pantai Melasti adalah perwujudan memukau dari keindahan alam Bali. Dibatasi oleh tebing batu kapur yang dramatis, pantai ini memiliki pasir putih bersih yang membentang mengundang menuju air laut biru jernih Samudera Hindia. Sekitarnya menawarkan pemandangan yang menakjubkan dan nuansa ketenangan. Ini memberikan pelarian yang tenang, di mana deburan lembut ombak menciptakan suasana yang benar-benar menenangkan.",

                    "shop": "Pengalaman mewah yang memadukan keindahan pantai, musik, dan rekreasi.",
                    "shop1": "Pasar Seni Kuta",
                    "shop1_1": "Terletak di jalan melasti No.86, Kuta, Kabupaten Badung, Bali. Pasar ini menjual berbagai suvenir khas Bali, seperti lukisan, gantungan kunci, pakaian, dan produk lokal lainnya dan menawarkan harga yang terjangkau",
                    "shop2": "Pasar Seni Merta Nadi",
                    "shop2_1": "Terletak di jalan melasti, Bali. Pasar ini menjual berbagai barang, seperti kacamata hitam, dress, kaus, topi dan sandal",

                    "other1": "Diadakan pada pukul 5 sore",
                    "other2": "Festival musik reggae",
                    "other3": "Dengan penampilan tari Kecak, tari Karnaval Air, dan Baleganjur",
                    "other4": "Nikmati acara tahun baru dengan pemandangan pantai",

                    "morning": "PAGI",
                    "afternoon": "SIANG",
                    "evening": "SORE",

                    "ihf_gallery": "GALERI",

                    sponsors: {
                        "title": "Ingin menjadi bagian dari Harley-Davidson® Festival di Indonesia?",
                        "subtitle": "Untuk menjadi sponsor silakan kontak:",
                        "contact1": "+62856-1647-949 - Sonny",
                        "contact2": "+62852-1198-3843 - Virella",
                    },

                    "contact1": "Anda dapat menghubungi kami melalui email di ",
                    "contact2": "atau menghubungi kami melalui ",
                    "contact3": "Untuk berita terkini, follow kami pada:",

                    "ck1": "Ikuti kompetisi ",
                    "ck2": "dan jadilah",
                    "ck3": "the King of the Kings.",

                    "required": "Harus diisi",

                    "phone-label": "Nomor Handphone",
                    "phone-placeholder": "Silakan masukkan nomor handphone Anda",

                    "name-label": "Nama",
                    "name-placeholder": "Silakan masukkan nama Anda",

                    "name-ck-label": "Nama Partisipan (customer / dealer / custom builder)",
                    "name-ck-placeholder": "Silakan masukkan nama",

                    "country-label": "Negara",
                    "country-placeholder": "Silakan masukkan negara Anda",

                    "email-label": "Alamat Email",
                    "email-placeholder": "Silakan masukkan alamat email Anda",

                    "bundle-label": "Pilih Bundle",
                    "bundle-placeholder": "Silakan pilih paket yang Anda inginkan",

                    "community-label": "Pilih Komunitas",
                    "community-placeholder": "Silakan pilih komunitas asal Anda",

                    "dealership-label": "Dealer Terkait",
                    "dealership-placeholder": "Silakan masukkan dealer terkait Anda",

                    "bike-label": "Nama Panggilan Motor",
                    "bike-placeholder": "Apa nama panggilan motor Anda?",

                    "donor-label": "Model Donor",
                    "donor-placeholder": "Silakan masukkan model donor Anda",

                    "pics-label": "Gambar dari 4 Sudut (specs)",
                    "pics-placeholder": "Silakan unggah 4 gambar motor Anda dari 4 sudut berbeda",

                    "video-label": "Video (opsional)",
                    "video-placeholder": "Anda dapat mengunggah video motor Anda di sini (opsional)",

                    "rideable-label": "Apakah motor Anda dapat dikendarai?",

                    "mileage-label": "Kilometer Saat Ini",
                    "mileage-placeholder": "Silakan masukkan kilometer saat ini",

                    "genuine-label": "Apakah motor Anda menggunakan setidaknya 50% suku cadang asli Harley Davidson?",

                    "amount-label": "Jumlah yang perlu dibayar",
                    "payment-channel": "Methode Pembayaran",
                    "account-number": "Nomor Rekening",
                    "payment-label": "Silakan upload bukti pembayaran Anda disini",

                    "success-form": "Registrasi anda telah berhasil! Silakan cek email anda untuk melihat kode QR.",

                    "failed-form": "Maaf, terjadi kesalahan dalam registrasi anda. Mohon coba lagi dalam beberapa saat.",
                    "failed-form-email": "Email anda sudah terdaftar.",

                    "payment-instructions": {
                        "title": "Cara melakukan pembayaran",
                        "bank1": [
                            "Transfer jumlah yang tertera ke nomor rekening yang disediakan.",
                            "Gunakan kode referensi yang disebutkan dalam email konfirmasi.",
                            "Pastikan untuk menyelesaikan pembayaran sebelum tenggat waktu."
                        ],
                        "bank2": [
                            "Setor jumlah tersebut di cabang Bank 2 manapun.",
                            "Tuliskan nomor pesanan Anda pada slip setoran.",
                            "Serahkan slip setoran di loket."
                        ],
                        "bank3": [
                            "Bayar melalui platform perbankan online Bank 3.",
                            "Ikuti langkah-langkah yang dijelaskan di portal perbankan online.",
                            "Lampirkan tangkapan layar transaksi di email konfirmasi Anda."
                        ]
                    },

                    "faq1": "Anda dapat mengontak kami di ",
                    "faq2": "untuk informasi lebih lanjut.",

                    "faqs": [
                        {
                            question: "Bagaimana cara mendaftar?",
                            answer: "Anda dapat mendaftar untuk acara tersebut dengan mengunjungi halaman pendaftaran di situs web kami. Isi informasi yang diperlukan dan ikuti petunjuk untuk menyelesaikan pendaftaran Anda."
                        },
                        {
                            question: "Di mana saya bisa membeli tiket?",
                            answer: "Tiket dapat dibeli di situs web kami atau di pintu masuk acara, tergantung ketersediaan."
                        },
                        {
                            question: "Apa yang bisa saya bawa?",
                            answer: "Anda dapat membawa barang pribadi seperti botol air, makanan ringan, dan kamera. Silakan periksa pedoman acara di situs web kami untuk daftar rinci barang yang diizinkan dan dilarang."
                        }
                    ],

                    "program1": "Gerbang dibuka untuk peserta masuk ke lokasi acara dan menjelajahi area",
                    "program2": "Nikmati area F&B dan Sponsor, serta pertunjukan dari band lokal",
                    "program3": "Kedatangan Rolling Thunder dan permainan bersama MC",
                    "program4": "Dimulainya Kompetisi Video Touring H.O.G.™ dan Upacara Pembukaan",
                    "program5": "Pertunjukan DJ saat matahari terbenam",
                    "program6": "Istirahat dan berakhirnya Kompetisi Video Touring H.O.G.™",
                    "program7": "Konten yang disponsori dan pertunjukan dari band lokal",
                    "program8": "Permainan seru yang dipandu oleh MC dengan kesempatan bagi peserta untuk berpartisipasi dan memenangkan hadiah",
                    "program9": "Nikmati area F&B dan Sponsor, serta Pertunjukan DJ",
                    "program10": "Upacara Penghargaan",
                    "program11": "Pertunjukan oleh bintang tamu yang akan segera diumumkan"
                }
            }
        },
        lng: 'id',
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;
